import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Section from '~components/Section'
import { Heart, Logo, SearchIcon, ShoppingCart, UserIcon, Line, LineMobile, UserIconMobile, ShoppingCartMobile, SearchIconMobile, HeartMobile, MobileHamburger } from '../../Svg';
import { Link } from "gatsby"
import { useSiteState, useCart, useWishlist } from '~context/siteContext'

const Header = ({ className }) => {

  const [siteState, setSiteState] = useSiteState()

  const { cartCount } = useCart()
  const { wishlistCount } = useWishlist()

  const {tablet} = breakpoints

  return (
    <Section
      className={className}
      css={css`
      position: relative;
      z-index: 90;
      ${tablet}{
        pointer-events: none;
      }
      > div{
        align-items: center;
        height: 104px;
        ${tablet}{
          height: 76px;
        }
      }
    `}>
      <Link to="/" css={css`
        grid-column: 1;
        grid-row: 1;
        width: 152px;
        display: block;
        transition: opacity 0.3s;
        pointer-events: all;
        ${tablet}{
          width: 110px;
          opacity: ${siteState.menuOpen ? 0 : 1};
          pointer-events: ${siteState.menuOpen ? 'none' : 'all'};
        }
      `}>
      <Logo />
      </Link>
      <div css={css`
        grid-row: 1;
        grid-column: 3 / 13;
        justify-self: end;
        display: grid;
        grid-template-columns: repeat(5, max-content);
        align-items: center;
        pointer-events: all;
        button, a {
          /* pointer-events: all; */
        }
      `}>
        <Link
          to='/account'
          css={css`
            width: 19.5px;
            height: 21.5px;
            margin-right: 19px;
            transform: translateY(0px);
            transition: 0.3s transform;
            &:hover {
              transform: translateY(-3px);
            }
            ${tablet}{
              margin-right: 20px;
              width: 16px;
              height: 19px;
              color: ${siteState.menuOpen && !siteState.hideIcons ? `var(--black)` : `var(--beige)`};
              pointer-events: ${siteState.menuOpen && !siteState.hideIcons ? `all` : `none`};
              transition: 0.3s color;
              &:hover {
                transform: none;
              }
            }
        `}>
          <UserIcon css={css`${tablet}{display: none;}`}/>
          <UserIconMobile css={css`display: none; ${tablet}{display: block;}`}/>
        </Link>
        <Link
          to="/wishlist"
          css={css`
            width: 22.4px;
            height: 19.71px;
            margin-right: 19px;
            position: relative;
            transition: 0.3s transform;
            .h6{
              position: absolute;
              top: 0;
              right: 0;
              transform: translate(100%, -60%);
            }
            &:hover {
              transform: translateY(-3px);
            }
            ${tablet}{
              width: 19px;
              height: 17px;
              margin-right: 20px;
              color: ${siteState.menuOpen && !siteState.hideIcons ? `var(--black)` : `var(--beige)`};
              pointer-events: ${siteState.menuOpen && !siteState.hideIcons ? `all` : `none`};
              transition: 0.3s color;
              &:hover {
                transform: none;
              }
            }
        `}>
          <Heart css={css`${tablet}{display: none;}`}/>
          <HeartMobile css={css`display: none; ${tablet}{display: block;}`}/>
          <div className="h6">{wishlistCount() > 0 ? wishlistCount() : null}</div>
        </Link>
        <button
          onClick={() => setSiteState({...siteState, searchOpen: !siteState.searchOpen})}
          css={css`
            width: 20px;
            height: 20px;
            margin-right: 19px;
            transform: translateY(0px);
            transition: 0.3s transform;
            &:hover {
              transform: translateY(-3px);
            }
            ${tablet}{
              margin-right: 17px;
              width: 17px;
              height: 17px;
              color: ${siteState.menuOpen && !siteState.hideIcons ? `var(--black)` : `var(--beige)`};
              pointer-events: ${siteState.menuOpen && !siteState.hideIcons ? `all` : `none`};
              transition: 0.3s color;
              &:hover {
                transform: none;
              }
            }
        `}>
          <SearchIcon css={css`${tablet}{display: none;}`}/>
          <SearchIconMobile css={css`display: none; ${tablet}{display: block;}`}/>
        </button>
        <Link
          to="/bag"
          css={css`
            width: 19.5px;
            height: 19.5px;
            margin-right: 38px;
            position: relative;
            transform: translateY(0px);
            transition: 0.3s transform;
            &:hover {
              transform: translateY(-3px);
            }
            .h6{
              position: absolute;
              top: 0;
              right: 0;
              transform: translate(100%, -60%);
            }
            ${tablet}{
              margin-right: 19px;
              width: 17px;
              height: 17px;
              color: ${siteState.hideIcons ? `var(--beige)` : `var(--black)`};
              pointer-events: ${siteState.hideIcons ? `none` : `all`};
              transition: 0.3s color;
              &:hover {
                transform: none;
              }
            }
        `}>
          <ShoppingCart css={css`${tablet}{display: none;}`}/>
          <ShoppingCartMobile css={css`display: none; ${tablet}{display: block;}`}/>
          <div className="h6">{cartCount() > 0 ? cartCount() : null}</div>
        </Link>
        <button onClick={() => setSiteState({...siteState, menuOpen: !siteState.menuOpen})} css={css`
          display: flex;
          align-items: center;
        `}>
          <div
            className="h6"
            css={css`
              margin-right: 24px;
              min-width: 42px;
              ${tablet}{
                display: none;
              }
          `}>
            {!siteState.menuOpen ? 'Menu' : 'Close'}
          </div>
          {/* desktop Hamburger */}
          <div css={css`
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            min-height: 16px;
            box-sizing: border-box;
            padding: 2px;
            ${tablet}{
              display: none;
            }
            > svg{
              width: 20px;
              /* display: block; */
              transition: transform 0.2s;
              &:first-of-type{
                transform: ${!siteState.menuOpen ? 'translateY(0)' : 'translateY(5px)'};
              }
              &:last-of-type{
                transform: ${!siteState.menuOpen ? 'translateY(0)' : 'translateY(-5px)'};
              }
            }
          `}>
            <Line/>
            <Line/>
            <Line css={css`align-self: end;`}/>
          </div>
          {/* Mobile Hamburger */}
          <div css={css`
            display: none;
            /* justify-content: space-between; */
            /* flex-direction: column; */
            box-sizing: border-box;
            padding: 2px 0px 0px 0px;
            min-height: 15px;
            ${tablet}{
              display: block;
            }
            > svg{
              width: 20px;
              display: block;
              line-height: 0;
              transition: transform 0.2s;
              margin-bottom: 0px;
              path{
                transition: transform 0.3s;
                &:nth-of-type(2){
                transform: ${!siteState.menuOpen ? 'translateY(0)' : 'translateY(6px)'};
                margin-bottom: 3px;
                display: block;
                }
                &:first-of-type{
                  transform: ${!siteState.menuOpen ? 'translateY(0)' : 'translateY(-6px)'};
                  margin-bottom: 0;
                  display: block;
                }
              }
            }
          `}>
            <MobileHamburger />
          </div>
        </button>
      </div>
    </Section>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

Header.defaultProps = {
}

export default Header
